<script setup lang="ts">
const { defineOgMeta } = useSeoAttributes()
const { fetch } = useHome()
const lp = useLocalePath()
const {
  public: { baseUrl },
} = useRuntimeConfig()

const { pending, data } = await useAsyncData('home', async () => fetch())

if (data.value) {
  useHead(() => ({
    title: data.value!.page_meta.title,
    link: [{ rel: 'canonical', href: `${baseUrl}/` }],
    meta: defineOgMeta(data.value!.page_meta),
  }))
}
</script>

<template>
  <Loader :pending="pending">
    <main class="flex w-full flex-col overflow-hidden">
      <Hero />
      <Team />
      <Reviews />
      <LazyOurWork />
      <LazyTechStack :data="data?.tech" />
      <LazyClients />
      
      <LazyCta :href="lp('/contact')" :label="$t('home.contact.ctaLabel')" />
      <LazyFaq />
      <Container class="h-32" />
    </main>
  </Loader>
</template>
